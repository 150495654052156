import {useState, useEffect, useRef} from 'react';
import {getAccount, getCurrencies, getLanguages, getSwapRates} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";

const useSwapRates = () => {
    const { user, triggerVibration, webApp  } = useTelegram();

    const [swapRates, setSwapRates] = useState(null);
    const [loadingSwapRates, setLoadingSwapRates] = useState(true);
    const [errorSwapRates, setErrorSwapRates] = useState(null);


    const fetchSwapRates = async (currencyFrom, currencyTo) => {

        setLoadingSwapRates(true);

        const {error, data} = await getSwapRates({
            "currency-from" : currencyFrom,
            "currency-to" : currencyTo,
        }, webApp?.initData);

        if (error) {
            console.log('ERRROR!', error);
            setErrorSwapRates(error);
            setLoadingSwapRates(false);

        } else {

            setSwapRates(data);
            setLoadingSwapRates(false);

            console.log(data, 'data')

        }

    };
    return { swapRates, loadingSwapRates, errorSwapRates, fetchSwapRates };
};

export default useSwapRates;
